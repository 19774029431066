import { css } from 'styled-components';
export default css(["", ";h5{color:var(--sc-review-card--text-color,", ");}", ""], function (props) {
  var _props$theme, _props$theme$form;
  return props.isVerticalView ? ".panels-wrap{ border-left: 1px solid var(--sc-form--border-color, ".concat((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$form = _props$theme.form) === null || _props$theme$form === void 0 ? void 0 : _props$theme$form.borderColor, ");}") : '';
}, function (props) {
  var _props$theme2, _props$theme2$reviewC;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$reviewC = _props$theme2.reviewCard) === null || _props$theme2$reviewC === void 0 ? void 0 : _props$theme2$reviewC.reviewTextColor;
}, function (props) {
  if (props.type === 'big') {
    return css(["&.nva-tabs--big{}"]);
  }
});