import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "type", "tabsContent", "tabsId", "initialSelectedIndex", "isVerticalView", "tabWrapClassName", "panelWrapClassName", "onTabChange", "activeTab"];
var __jsx = React.createElement;
/**
 * Tabs
 */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import styles from './Tabs.style';
import Tab from './Tab';
import TabPanel from './TabPanel';
import List from '../../molecules/List';
import Para from '../../atoms/Para';
var Tabs = function Tabs(_ref) {
  var className = _ref.className,
    type = _ref.type,
    tabsContent = _ref.tabsContent,
    tabsId = _ref.tabsId,
    initialSelectedIndex = _ref.initialSelectedIndex,
    isVerticalView = _ref.isVerticalView,
    tabWrapClassName = _ref.tabWrapClassName,
    panelWrapClassName = _ref.panelWrapClassName,
    onTabChange = _ref.onTabChange,
    activeTab = _ref.activeTab,
    others = _objectWithoutProperties(_ref, _excluded);
  var _useState = useState(initialSelectedIndex || 0),
    selectedIndex = _useState[0],
    setSelectedIndex = _useState[1];
  useEffect(function () {
    if (selectedIndex !== selectedIndex && activeTab) {
      setSelectedIndex(selectedIndex);
    }
  }, [selectedIndex, activeTab]);
  var updateTabChange = function updateTabChange(index) {
    setSelectedIndex(index);
    if (onTabChange) {
      onTabChange(index);
    }
  };
  var tabClick = function tabClick(e, index) {
    e.preventDefault();
    if (selectedIndex === index) {
      return;
    }
    updateTabChange(index);
  };
  var listOptions = {
    noDefaultView: true,
    inline: !isVerticalView,
    noMargin: true
  };
  return __jsx("div", _extends({
    id: tabsId,
    className: cn('nva-tabs ', {
      row: isVerticalView
    }, className)
  }, others), __jsx("div", {
    className: cn('nva-tabs__wrapper tabs-wrap', tabWrapClassName)
  }, __jsx(List, _extends({
    role: "tablist"
  }, listOptions, {
    className: "nva-tabs"
  }), tabsContent && tabsContent.map(function (item, index) {
    return __jsx(Tab, {
      className: "nva-tabs__tab ".concat(item.tabClassName || ''),
      index: index,
      isSelected: index === selectedIndex,
      onClick: function onClick(e) {
        return tabClick(e, index);
      },
      tabId: "tab-list-item-".concat(index),
      key: "tab-".concat(index.toString()),
      anchorTitle: item.tabTitle
    }, __jsx(Para, {
      className: "nva-tabs__tab-heading heading_five"
    }, item.tabTitle));
  }))), __jsx("div", {
    className: cn('nva-tabs__panels-wrapper panels-wrap', panelWrapClassName)
  }, tabsContent && tabsContent.map(function (item, index) {
    return __jsx(TabPanel, {
      className: "nva-tabs__tab-panel ".concat(item.panelClassName || ''),
      isSelected: index === selectedIndex,
      tabId: "tab-list-item-".concat(index),
      index: index,
      key: "tab-panel-".concat(index.toString())
    }, item.panelContent);
  })));
};
Tabs.defaultProps = {
  className: '',
  tabsId: '',
  initialSelectedIndex: 0,
  selectedIndex: 0,
  isVerticalView: false,
  tabWrapClassName: '',
  panelWrapClassName: ''
};
export default styled(Tabs).withConfig({
  componentId: "sc-ykh7ny-0"
})(["", ";"], styles);
export { Tabs as TabsVanilla };